import Section from "../../../Section";
//import BuildProjectWizard from "../../../../components/BuildProjectWizard/BuildProjectWizard";
import "./Home.less";

function Home() {
    //const [buildProductOpen, setBuildProductOpen] = React.useState<boolean>(false);

    return (
        <Section id="home" textColor="grey">
            <h1>We build digital solutions that solve real-life problems and are tailored around your needs.</h1>
            <div className="row">
                <div>
                    <p className="nowrap-text">
                        Project42 is a digital agency that specializes in design, development, animation and extended reality.
                    </p>
                    {/**
                     <Link
                         to="#"
                         className={buildProductOpen ? "non-underlined" : ""}
                         onClick={() => setBuildProductOpen(!buildProductOpen)}
                     >
                         Build a product
                     </Link>
                     */}
                </div>
            </div>

            {/*
                <div className="wizard-container">
                    <BuildProjectWizard open={buildProductOpen} />
                </div>
            */}
        </Section>
    );
}

export default Home;
